import { useSiteContexto } from "context/context";
export const LAYOUT = {
  main: "main",
  noauth: "",
  minimal: "minimal",
  suites: {
    caracteristicas: {
      icon: {
        size: {
          default: "8px",
          xs: "8px",
          sm: "8px",
          md: "8px",
          lg: "8px",
          xl: "8px",
        },
        color: "#d09e2c",
      },
      fontSize: {
        default: "14px",
        xs: "14px",
        sm: "14px",
        md: "14px",
        lg: "14px",
        xl: "14px",
      },
      lineHeight: {
        default: "2",
        xs: "2",
        sm: "2",
        md: "2",
        lg: "2",
        xl: "2",
      },
    },
  },
  margin: {
    m1: "15px",
    m2: "20px",
    m3: "30px",
    m4: "40px",
  },
  padding: {
    m1: "15px",
    m2: "20px",
    m3: "30px",
    m4: "40px",
  },
  colors: {
    cinza: "#848484",
    dourado: "#d09e2c",
    roxo: "#771d50",
    laranja: "#ff8f00",
    tiffany: "#3ACBC7",
    tiffanyClaro: "#d8f4f3",
    fundoInput: "#f1f1f1",
  },
  containers: {
    principal: {
      margin: {
        default: "0px 0px 50px 0px!important",
        xs: "0px 0px 50px 0px!important",
        sm: "0px 0px 50px 0px!important",
        md: "0px 0px 50px 0px!important",
        lg: "0px 0px 50px 0px!important",
        xl: "0px 0px 50px 0px!important",
      },
      padding: {
        default: "0px 0px 0px 0px",
        xs: "0px 0px 0px 0px",
        sm: "0px 0px 0px 0px",
        md: "0px 0px 0px 0px",
        lg: "0px 0px 0px 0px",
        xl: "0px 0px 0px 0px",
      },
    },
    blocoSite: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: {
        default: "20px 0px 0px 0px!important",
        xs: "20px 0px 0px 0px!important",
        sm: "20px 0px 0px 0px!important",
        md: "20px 0px 0px 0px!important",
        lg: "20px 0px 0px 0px!important",
        xl: "30px 0px 0px 0px!important",
      },
      padding: {
        default: "0px 0px 0px 0px",
        xs: "0px 0px 0px 0px",
        sm: "0px 0px 0px 0px",
        md: "0px 0px 0px 0px",
        lg: "0px 0px 0px 0px",
        xl: "0px 0px 0px 0px",
      },
    },
  },
  fonts: {
    titulo: "'Cinzel', serif",
    descricao: "'Montserrat', sans-serif",
    family: {
      titulo: "'Cinzel', serif",
      descricao: "'Montserrat', sans-serif",
    },
    fontSize: {
      default: "16px",
      titulo: {
        default: "24px",
        xs: "24px",
        sm: "24px",
        md: "26px",
        lg: "26px",
        xl: "28px",
      },
      descricao: {
        default: "12px",
        xs: "13px",
        sm: "12px",
        md: "14px",
        lg: "14px",
        xl: "16px",
      },
    },
    lineHeight: {
      titulo: {
        default: "1.5",
        xs: "1.5",
        sm: "1.5",
        md: "1.5",
        lg: "1.5",
        xl: "1.5",
      },
      descricao: {
        default: "1.5",
        xs: "1.5",
        sm: "1.5",
        md: "1.5",
        lg: "1.5",
        xl: "1.5",
      },
    },
    textAlign: {
      titulo: {
        default: "center",
        xs: "center",
        sm: "center",
        md: "center",
        lg: "center",
        xl: "center",
      },
      descricao: {
        default: "justify",
        xs: "justify",
        sm: "justify",
        md: "justify",
        lg: "justify",
        xl: "justify",
      },
    },
    fontWeight: {
      titulo: {
        default: "400",
        xs: "400",
        sm: "400",
        md: "400",
        lg: "400",
        xl: "400",
      },
      descricao: {
        default: "400",
        xs: "400",
        sm: "400",
        md: "400",
        lg: "400",
        xl: "400",
      },
    },
    margin: {
      titulo: {
        default: "0px 0px 0px 0px",
        xs: "0px 0px 0px 0px",
        sm: "0px 0px 0px 0px",
        md: "0px 0px 0px 0px",
        lg: "0px 0px 0px 0px",
        xl: "0px 0px 0px 0px",
      },
      descricao: {
        default: "15px 0px 0px 0px",
        xs: "15px 0px 0px 0px",
        sm: "15px 0px 0px 0px",
        md: "15px 0px 0px 0px",
        lg: "15px 0px 0px 0px",
        xl: "15px 0px 0px 0px",
      },
    },
    padding: {
      titulo: {
        default: "0px 0px 0px 0px",
        xs: "0px 0px 0px 0px",
        sm: "0px 0px 0px 0px",
        md: "0px 0px 0px 0px",
        lg: "0px 0px 0px 0px",
        xl: "0px 0px 0px 0px",
      },
      descricao: {
        default: "0px 20px 0px 20px",
        xs: "0px 20px 0px 20px",
        sm: "0px 20px 0px 20px",
        md: "0px 20px 0px 20px",
        lg: "0px 20px 0px 20px",
        xl: "0px 20px 0px 20px",
      },
    },
    fontColor: {
      titulo: {
        default: "#848484",
        gray: "#848484",
        white: "#fff",
        golden: "#d09e2c",
        lg: "#848484",
        xl: "#848484",
      },
      descricao: {
        default: "#848484",
        gray: "#848484",
        white: "#fff",
        md: "#848484",
        lg: "#848484",
        xl: "#848484",
      },
    },
  },
  footer: {
    fonts: {
      fontSize: {
        default: "12px",
        titulo: {
          default: "24px",
          xs: "24px",
          sm: "24px",
          md: "24px",
          lg: "24px",
          xl: "28px",
        },
        subTitulo: {
          default: "12px",
          xs: "12px",
          sm: "12px",
          md: "12px",
          lg: "12px",
          xl: "14px",
        },
        descricao: {
          default: "12px",
          xs: "12px",
          sm: "12px",
          md: "12px",
          lg: "12px",
          xl: "14px",
        },
      },
      lineHeight: {
        titulo: {
          default: "1.5",
          xs: "1.5",
          sm: "1.5",
          md: "1.5",
          lg: "1.5",
          xl: "1.5",
        },
        subTitulo: {
          default: "1.5",
          xs: "1.5",
          sm: "1.5",
          md: "1.5",
          lg: "1.5",
          xl: "1.5",
        },
        descricao: {
          default: "1.5",
          xs: "1.5",
          sm: "1.5",
          md: "1.5",
          lg: "1.5",
          xl: "1.5",
        },
      },
      textAlign: {
        titulo: {
          default: "center",
          xs: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "center",
        },
        subTitulo: {
          default: "center",
          xs: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "center",
        },
        descricao: {
          default: "justify",
          xs: "justify",
          sm: "justify",
          md: "justify",
          lg: "justify",
          xl: "justify",
        },
      },
      fontWeight: {
        titulo: {
          default: "400",
          xs: "400",
          sm: "400",
          md: "400",
          lg: "400",
          xl: "400",
        },
        subTitulo: {
          default: "400",
          xs: "400",
          sm: "400",
          md: "400",
          lg: "400",
          xl: "400",
        },
        descricao: {
          default: "400",
          xs: "400",
          sm: "400",
          md: "400",
          lg: "400",
          xl: "400",
        },
      },
      margin: {
        titulo: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
        subTitulo: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
        descricao: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
      },
      padding: {
        titulo: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
        subTitulo: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
        descricao: {
          default: "0px 0px 0px 0px",
          xs: "0px 0px 0px 0px",
          sm: "0px 0px 0px 0px",
          md: "0px 0px 0px 0px",
          lg: "0px 0px 0px 0px",
          xl: "0px 0px 0px 0px",
        },
      },
      fontColor: {
        titulo: {
          default: "#848484",
          gray: "#848484",
          white: "#fff",
          golden: "#d09e2c",
          lg: "#848484",
          xl: "#848484",
        },
        subTitulo: {
          default: "#848484",
          gray: "#848484",
          white: "#fff",
          golden: "#d09e2c",
          lg: "#848484",
          xl: "#848484",
        },
        descricao: {
          default: "#848484",
          gray: "#848484",
          white: "#fff",
          md: "#848484",
          lg: "#848484",
          xl: "#848484",
        },
      },
    },
  },
};

export default LAYOUT;
