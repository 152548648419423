import { useMediaQuery } from "@mui/material";
import { createContext, useContext, useState } from "react";

const SiteContexto = createContext<any>(null);

export const useSiteContexto = () => {
  return useContext(SiteContexto);
};
export const SiteContextoProvider = ({
  menu,
  restricoes,
  configSite,
  children,
}: any) => {
  const larguraTela = useMediaQuery("(min-width:962px)");

  const [openMenu, setOpenMenu] = useState(false);
  // Define o estado ou valores que deseja compartilhar
  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const dadosCompartilhados = {
    tamanhoTela: larguraTela,
    idioma: "pt-BR",
    menu: menu,
    openMenu,
    restricoes: restricoes,
    configSite: configSite,
    handleOpenMenu,
    handleCloseMenu,
  };

  return (
    <SiteContexto.Provider
      value={{
        ...dadosCompartilhados,
        handleCloseMenu,
      }}
    >
      {children}
    </SiteContexto.Provider>
  );
};

export default SiteContextoProvider;
