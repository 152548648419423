import { NextPageContext } from "next";
import { randomBytes } from "crypto";
import { parse, serialize } from "cookie";

export async function dataCookie({ ctx }: { ctx: any }) {
  let sessionId = randomBytes(16).toString("hex");
  if (ctx.req && ctx.res) {
    const cookies = parse(ctx.req.headers.cookie || "");
    if (!cookies["session-id"]) {
      // Gerar um ID aleatório (UUID)

      // Definir o cookie no cabeçalho da resposta
      ctx.res.setHeader(
        "Set-Cookie",
        serialize("session-id", sessionId, {
          httpOnly: true, // Impede o acesso ao cookie via JavaScript // Envia o cookie apenas por HTTPS em produção
          maxAge: 60 * 60 * 24 * 7, // Expira em 1 semana
          path: "/", // O cookie será acessível em toda a aplicação
          sameSite: false, // Restringe o acesso ao cookie apenas a mesma origem
          secure: false, // Envia o cookie apenas por HTTPS em produção
        })
      );
    } else {
      sessionId = cookies["session-id"]?.toString() || "";
    }
  }
  return sessionId.toString();
}
