import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import LAYOUT from "components/Constants";

export interface DefaultTheme {
  borderRadius?: string;
  padding?: string;
  fontWeight?: string;
  importFont?: string;
  fontFamily?: string;
  btnSite?: string;
  btnPrimary?: string;
  DescricaoColor?: string;
  TituloColor?: string;
  bodyColor?: string;
  CategoriasHomeColor?: string;
  SeparadorColor?: string;
  CorMenu?: string;
  btnTextColor?: string;
  accordionColor?: string;
  config?: {};

  colors?: {
    main?: string;
    secondary?: string;
    orange?: string;
  };
}

const GlobalStyles = createGlobalStyle<DefaultTheme>`
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;700;800&family=Noto+Sans+JP&family=Protest+Riot&family=Quicksand:wght@300;400;500;600;700&display=swap'); */
:root{
    --btn-site: ${(props) => props.btnSite};
    --btn-color-primary: ${(props) => props.btnPrimary}
}

@font-face {
  font-family: 'Trajan Pro';
  src: url('/fonts/TrajanPro-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trajan Pro';
  src: url('/fonts/TrajanPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

*{
    margin: 0;
    padding: ${(props) => props.padding};
    box-sizing: border-box;
}
img {
  object-fit: cover; /* ou 'contain', dependendo do efeito desejado */
}
html{
    font-size: 12px;
}
html, body, #__next{
    height: 100%;
    margin: 0px;
}
body{
  -webkit-font-smoothing: antialiased !important;
  -webkit-touch-callout: none!important;
  -webkit-text-size-adjust: none!important;
  line-height: 1.5!important;
}
#__next{
    flex-direction: column;
    align-items: center;
    margin: 0px;
    display: block;
}

.formPagamento{
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  height: 40px;
  min-height: 60px;
  display: flex;
  justify-content: start;
  align-items: start;
}
.gtranslate_wrapper.tradutor #gt_float_wrapper{
  top: 10px!important;
  right: 10px!important;
}
.gtranslate_wrapper.tradutor #gt_float_wrapper .gt_float_switcher {
    font-family: Arial;
    font-size: 12px;
    border-radius: 2px;
    color: #555;
    display: inline-block;
    line-height: 2px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0 5px 15px; */
    background: #fff;
    overflow: hidden;
    transition: all .5s cubic-bezier(0.4, 0, 1, 1);
}
.logoGastronomia{
  object-fit: contain!important;
}
.formPagamento.open{
  display: flex;
  justify-content: start;
  align-items: start;
  @media only screen and (min-width: 0px) {
    flex-direction: row-reverse;
    height: 1300px;
  }
  @media only screen and (min-width: 600px) {
    height: 800px;
  }
  @media only screen and (min-width: 900px) {
    height: 860px;
  }
  @media only screen and (min-width: 1200px) {
    height: 930px;
  }
  @media only screen and (min-width: 1536px) {
    height: 830px;
  }
}
.formPagamento.closed{
}
.blocoPagamento{
  @media only screen and (max-width: 600px) {
    flex-direction: row-reverse!important;
  }
  

}

.cardSuitesInfos{
  display: flex;
  @media only screen and (min-width: 0px) {
    display: none;
  }
  @media only screen and (min-width: 600px) {
    display: flex;
  }
  @media only screen and (min-width: 900px) {
    display: flex;
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
  }
  @media only screen and (min-width: 1536px) {
    display: flex;
  }
}
.containerFull{
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  display: block;
  min-height: 450px;
  @media only screen and (min-width: 0px) {
    height: calc(100vh - 300px);
  }
  @media only screen and (min-width: 600px) {
    height: calc(100vh - 400px);
  }
  @media only screen and (min-width: 900px) {
    height: calc(100vh - 400px);
  }
  @media only screen and (min-width: 1200px) {
    height: calc(100vh - 400px);
  }
  @media only screen and (min-width: 1536px) {
    height: calc(100vh - 160px);
  }
}

.containerSite{
  margin: ${LAYOUT.containers.principal.margin.default};
}



.textoDescricao {
  font-family: ${LAYOUT.fonts.family.descricao};
  font-size: ${LAYOUT.fonts.fontSize.descricao.default};
  line-height: ${LAYOUT.fonts.lineHeight.descricao.default};
  text-align: ${LAYOUT.fonts.textAlign.descricao.default};
  font-weight: ${LAYOUT.fonts.fontWeight.descricao.default};
  margin: ${LAYOUT.fonts.margin.descricao.default};
  padding: ${LAYOUT.fonts.padding.descricao.default};
  color: ${LAYOUT.fonts.fontColor.descricao.default};
  @media only screen and (min-width: 0px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.fonts.fontSize.descricao.xs};
    line-height: ${LAYOUT.fonts.lineHeight.descricao.xs};
    text-align: ${LAYOUT.fonts.textAlign.descricao.xs};
    font-weight: ${LAYOUT.fonts.fontWeight.descricao.xs};
    margin: ${LAYOUT.fonts.margin.descricao.xs};
    padding: ${LAYOUT.fonts.padding.descricao.xs};
    color: ${LAYOUT.fonts.fontColor.descricao.gray};
  }
  @media only screen and (min-width: 600px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.fonts.fontSize.descricao.sm};
    line-height: ${LAYOUT.fonts.lineHeight.descricao.sm};
    text-align: ${LAYOUT.fonts.textAlign.descricao.sm};
    font-weight: ${LAYOUT.fonts.fontWeight.descricao.sm};
    margin: ${LAYOUT.fonts.margin.descricao.sm};
    padding: ${LAYOUT.fonts.padding.descricao.sm};
    color: ${LAYOUT.fonts.fontColor.descricao.gray};
  }
  @media only screen and (min-width: 900px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.fonts.fontSize.descricao.md};
    line-height: ${LAYOUT.fonts.lineHeight.descricao.md};
    text-align: ${LAYOUT.fonts.textAlign.descricao.md};
    font-weight: ${LAYOUT.fonts.fontWeight.descricao.md};
    margin: ${LAYOUT.fonts.margin.descricao.md};
    padding: ${LAYOUT.fonts.padding.descricao.md};
    color: ${LAYOUT.fonts.fontColor.descricao.gray};
  }
  @media only screen and (min-width: 1200px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.fonts.fontSize.descricao.lg};
    line-height: ${LAYOUT.fonts.lineHeight.descricao.lg};
    text-align: ${LAYOUT.fonts.textAlign.descricao.lg};
    font-weight: ${LAYOUT.fonts.fontWeight.descricao.lg};
    margin: ${LAYOUT.fonts.margin.descricao.lg};
    padding: ${LAYOUT.fonts.padding.descricao.lg};
    color: ${LAYOUT.fonts.fontColor.descricao.gray};
  }
  @media only screen and (min-width: 1536px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.fonts.fontSize.descricao.xl};
    line-height: ${LAYOUT.fonts.lineHeight.descricao.xl};
    text-align: ${LAYOUT.fonts.textAlign.descricao.xl};
    font-weight: ${LAYOUT.fonts.fontWeight.descricao.xl};
    margin: ${LAYOUT.fonts.margin.descricao.xl};
    padding: ${LAYOUT.fonts.padding.descricao.xl};
    color: ${LAYOUT.fonts.fontColor.descricao.gray};
  }
}

.msgDisponibilidade{
  min-height: calc(100vh - 420px);
  @media only screen and (min-width: 0px) {
    min-height: calc(100vh - 570px)!important;
  }
  @media only screen and (min-width: 600px) {
    min-height: calc(100vh - 470px)!important;
  }
  @media only screen and (min-width: 900px) {
    min-height: calc(100vh - 490px)!important;
  }
  @media only screen and (min-width: 1200px) {
    min-height: calc(100vh - 390px)!important;
  }
  @media only screen and (min-width: 1536px) {
    min-height: calc(100vh - 420px)!important;
  }
}

.footerSite{
  height: 350px;
  @media only screen and (min-width: 0px) {
    height: 500px!important;
  }
  @media only screen and (min-width: 600px) {
    height: 400px!important;
  }
  @media only screen and (min-width: 900px) {
    height: 420px!important;
  }
  @media only screen and (min-width: 1200px) {
    height: 320px!important;
  }
  @media only screen and (min-width: 1536px) {
    height: 350px!important;
  }
}

.footerSite .textoDescricao {
  font-family: ${LAYOUT.fonts.family.descricao};
  font-size: ${LAYOUT.footer.fonts.fontSize.default};
  line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.default};
  text-align: ${LAYOUT.footer.fonts.textAlign.descricao.default};
  font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.default};
  margin: ${LAYOUT.footer.fonts.margin.descricao.default};
  padding: ${LAYOUT.footer.fonts.padding.descricao.default};
  color: ${LAYOUT.fonts.fontColor.descricao.white};
  @media only screen and (min-width: 0px) {
    font-family: ${LAYOUT.fonts.family.descricao};
  font-size: ${LAYOUT.footer.fonts.fontSize.descricao.xs};
  line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.xs};
  text-align: ${LAYOUT.footer.fonts.textAlign.descricao.xs};
  font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.xs};
  margin: ${LAYOUT.footer.fonts.margin.descricao.xs};
  padding: ${LAYOUT.footer.fonts.padding.descricao.xs};
  color: ${LAYOUT.fonts.fontColor.descricao.white};
  }
  @media only screen and (min-width: 600px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.footer.fonts.fontSize.descricao.sm};
    line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.sm};
    text-align: ${LAYOUT.footer.fonts.textAlign.descricao.sm};
    font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.sm};
    margin: ${LAYOUT.footer.fonts.margin.descricao.sm};
    padding: ${LAYOUT.footer.fonts.padding.descricao.sm};
    color: ${LAYOUT.fonts.fontColor.descricao.white};
  }
  @media only screen and (min-width: 900px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.footer.fonts.fontSize.descricao.md};
    line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.md};
    text-align: ${LAYOUT.footer.fonts.textAlign.descricao.md};
    font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.md};
    margin: ${LAYOUT.footer.fonts.margin.descricao.md};
    padding: ${LAYOUT.footer.fonts.padding.descricao.md};
    color: ${LAYOUT.fonts.fontColor.descricao.white};
  }
  @media only screen and (min-width: 1200px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.footer.fonts.fontSize.descricao.lg};
    line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.lg};
    text-align: ${LAYOUT.footer.fonts.textAlign.descricao.lg};
    font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.lg};
    margin: ${LAYOUT.footer.fonts.margin.descricao.lg};
    padding: ${LAYOUT.footer.fonts.padding.descricao.lg};
    color: ${LAYOUT.fonts.fontColor.descricao.white};
  }
  @media only screen and (min-width: 1536px) {
    font-family: ${LAYOUT.fonts.family.descricao};
    font-size: ${LAYOUT.footer.fonts.fontSize.descricao.xl};
    line-height: ${LAYOUT.footer.fonts.lineHeight.descricao.xl};
    text-align: ${LAYOUT.footer.fonts.textAlign.descricao.xl};
    font-weight: ${LAYOUT.footer.fonts.fontWeight.descricao.xl};
    margin: ${LAYOUT.footer.fonts.margin.descricao.xl};
    padding: ${LAYOUT.footer.fonts.padding.descricao.xl};
    color: ${LAYOUT.fonts.fontColor.descricao.white};
  }
}

.textoTituloDourado {
  font-family: ${LAYOUT.fonts.family.titulo};
  font-size: ${LAYOUT.fonts.fontSize.titulo.default};
  line-height: ${LAYOUT.fonts.lineHeight.titulo.default};
  text-align: ${LAYOUT.fonts.textAlign.titulo.default};
  font-weight: ${LAYOUT.fonts.fontWeight.titulo.default};
  margin: ${LAYOUT.fonts.margin.titulo.default};
  padding: ${LAYOUT.fonts.padding.titulo.default};
  color: ${LAYOUT.fonts.fontColor.titulo.golden};
  @media only screen and (min-width: 0px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.xs};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.xs};
    text-align: ${LAYOUT.fonts.textAlign.titulo.xs};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.xs};
    margin: ${LAYOUT.fonts.margin.titulo.xs};
    padding: ${LAYOUT.fonts.padding.titulo.xs};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 600px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.sm};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.sm};
    text-align: ${LAYOUT.fonts.textAlign.titulo.sm};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.sm};
    margin: ${LAYOUT.fonts.margin.titulo.sm};
    padding: ${LAYOUT.fonts.padding.titulo.sm};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 900px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.md};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.md};
    text-align: ${LAYOUT.fonts.textAlign.titulo.md};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.md};
    margin: ${LAYOUT.fonts.margin.titulo.md};
    padding: ${LAYOUT.fonts.padding.titulo.md};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 1200px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.lg};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.lg};
    text-align: ${LAYOUT.fonts.textAlign.titulo.lg};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.lg};
    margin: ${LAYOUT.fonts.margin.titulo.lg};
    padding: ${LAYOUT.fonts.padding.titulo.lg};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 1536px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.xl};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.xl};
    text-align: ${LAYOUT.fonts.textAlign.titulo.xl};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.xl};
    margin: ${LAYOUT.fonts.margin.titulo.xl};
    padding: ${LAYOUT.fonts.padding.titulo.xl};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
}

.subTitulo {
  font-family: ${LAYOUT.fonts.family.titulo};
  font-size: ${LAYOUT.fonts.fontSize.titulo.default};
  line-height: ${LAYOUT.fonts.lineHeight.titulo.default};
  text-align: ${LAYOUT.fonts.textAlign.titulo.default};
  font-weight: ${LAYOUT.fonts.fontWeight.titulo.default};
  margin: ${LAYOUT.fonts.margin.titulo.default};
  padding: ${LAYOUT.fonts.padding.titulo.default};
  color: ${LAYOUT.fonts.fontColor.titulo.golden};
  @media only screen and (min-width: 0px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.xs};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.xs};
    text-align: ${LAYOUT.fonts.textAlign.titulo.xs};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.xs};
    margin: ${LAYOUT.fonts.margin.titulo.xs};
    padding: ${LAYOUT.fonts.padding.titulo.xs};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 600px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.sm};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.sm};
    text-align: ${LAYOUT.fonts.textAlign.titulo.sm};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.sm};
    margin: ${LAYOUT.fonts.margin.titulo.sm};
    padding: ${LAYOUT.fonts.padding.titulo.sm};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 900px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.md};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.md};
    text-align: ${LAYOUT.fonts.textAlign.titulo.md};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.md};
    margin: ${LAYOUT.fonts.margin.titulo.md};
    padding: ${LAYOUT.fonts.padding.titulo.md};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 1200px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.lg};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.lg};
    text-align: ${LAYOUT.fonts.textAlign.titulo.lg};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.lg};
    margin: ${LAYOUT.fonts.margin.titulo.lg};
    padding: ${LAYOUT.fonts.padding.titulo.lg};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
  @media only screen and (min-width: 1536px) {
    font-family: ${LAYOUT.fonts.family.titulo};
    font-size: ${LAYOUT.fonts.fontSize.titulo.xl};
    line-height: ${LAYOUT.fonts.lineHeight.titulo.xl};
    text-align: ${LAYOUT.fonts.textAlign.titulo.xl};
    font-weight: ${LAYOUT.fonts.fontWeight.titulo.xl};
    margin: ${LAYOUT.fonts.margin.titulo.xl};
    padding: ${LAYOUT.fonts.padding.titulo.xl};
    color: ${LAYOUT.fonts.fontColor.titulo.golden};
  }
}

/* POSIÇÃO DO NOME DA SUITE */

.tituloSuiteDeskTop{
background-color: #f1f1f1;
padding: 8px;
margin-bottom: 10px;
  @media only screen and (min-width: 0px) {
    display: none!important;
  }
  @media only screen and (min-width: 600px) {
    display: none!important;
  }
  @media only screen and (min-width: 900px) {
    display: flex!important;
  }
  @media only screen and (min-width: 1200px) {
    display: flex!important;
  }
  @media only screen and (min-width: 1536px) {
    display: flex!important;
  }
}

.tituloSuiteMobile{
  background-color: #f1f1f1;
padding: 8px;
margin-bottom: 10px!important;
margin-top: 10px!important;
  @media only screen and (min-width: 0px) {
    display: flex!important;
  }
  @media only screen and (min-width: 600px) {
    display: flex!important;
  }
  @media only screen and (min-width: 900px) {
    display: none!important;
  }
  @media only screen and (min-width: 1200px) {
    display: none!important;
  }
  @media only screen and (min-width: 1536px) {
    display: none!important;
  }
}

a{
  text-decoration: none!important;
}

.menu {
  display: none;
}

.desktopMenu {
  display: flex!important;
}
.desktopImagemTopo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktopImagemMosaico {
  width: 100%;
  height: 100%;
}

.mobileImagemTopo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobileMenu {
  display: flex!important;
}

@media (min-width: 0px) {
  .mobileMenu {
    display: flex!important;
  }
  .desktopMenu {
    display: none!important;
  }
}

@media (min-width: 1200px) {
  .desktopMenu {
    display: flex!important;
  }
  .mobileMenu {
    display: none!important;
  }
}

.termoDayCard{
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000!important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 80%;
}
.termoDayCard::-webkit-scrollbar {
  right: 20px;
  width: 15px; /* Largura da barra de rolagem vertical */
  height: 15px; /* Altura da barra de rolagem horizontal */
}

/* Estiliza a parte da barra de rolagem (fundo) */
.termoDayCard::-webkit-scrollbar-track {
  background: ${LAYOUT.colors.fundoInput};
}

/* Estiliza o polegar da barra de rolagem */
.termoDayCard::-webkit-scrollbar-thumb {
  background-color: ${LAYOUT.colors.dourado}; /* Cor do polegar */
  border-radius: 10px; /* Arredondar o polegar */
  border: 3px solid #f1f1f1; /* Borda ao redor do polegar */
}

/* Estiliza o polegar ao passar o mouse */
.content-modalCaracteristicas::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Cor do polegar ao passar o mouse */
}

.termoDayCard li{
  text-decoration: none;
  list-style: none;
}

/* Remove as bordas arredondadas de input e select no iOS */
input, select {
  border-radius: 0!important;
  -webkit-appearance: none!important; /* Remove estilos padrões do Safari */
  appearance: none!important; /* Remove estilos padrões do navegador */
}

select {
  background-repeat: no-repeat!important;
  background-position: right 0px center!important;
  background-size: 20px 20px!important;
  padding-right: 30px!important;
}

.selectPacotes{
  background-color: transparent;
  padding: 8px 7px 7px 7px;
  margin: 0px;
  border: 1px solid ${LAYOUT.colors.dourado};
  color: ${LAYOUT.colors.dourado};
  box-sizing: border-box;
  font-size: 14px;
  height: 34px;
  appearance: none; /* Remove o estilo padrão do select */
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 210px;
  z-index: 1;
}
.selectPacotes::placeholder{
  color: #fff;
}
.selectPacotes:focus, .selectPacotes:active{
  box-shadow: 0 0 0 0;
  border: 1px solid ${LAYOUT.colors.dourado};
    outline: 0;
}

.selectPacotes option{
  background-color: #fff;
  padding: 7px;
  margin: 5px;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
  box-sizing: border-box;
  border-radius: 0px;
}
.selectPacotes option:hover{
  background-color: #000;
}
.selectPacotes option::placeholder{
  color: #6e6e6e;
}
.selectPacotes option:focus, option:active{
  box-shadow: 0 0 0 0;
  border: 1px solid #6e6e6e;
    outline: 0;
}

.custom-select-container {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-select{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 0;
}

/* Para garantir que o estilo seja aplicado de forma consistente, você pode adicionar o seguinte */
input:focus, select:focus {
  outline: none!important; /* Remove a linha de foco padrão */
}

.swiper-button-next, .swiper-button-prev {
    color: ${LAYOUT.colors.laranja}; /* Altere a cor conforme necessário */
    @media only screen and (min-width: 0px) {
    display: none!important;
  }
  @media only screen and (min-width: 600px) {
    display: flex!important;
  }
  @media only screen and (min-width: 900px) {
    display: flex!important;
  }
  @media only screen and (min-width: 1200px) {
    display: flex!important;
  }
  @media only screen and (min-width: 1536px) {
    display: flex!important;
  }
    
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px; /* Ajuste o tamanho das setas conforme necessário */
    font-weight: bold;
}
h1{
  color: #585858;
  letter-spacing: 1px;
  line-height: 1.1;
}
h2{
  color: #585858;
  letter-spacing: 1px;
}
h3{
  color: #585858;
  letter-spacing: 1px;
}
.quadrado {
  width: 10px;
  height: 10px;
  background: #3ACBC7;
  margin-right: 5px;
}
h5{
  letter-spacing: 1px;
}
.primeiroMenu::after{
    display: none!important;
}
h6{
  font-family: ${(props) => props.fontFamily};
  color: #585858;
  font-size: 18px;
  letter-spacing: 1px;
}
span{
  letter-spacing: 1px;
  font-weight: 300;
}
p{
  font-family: ${(props) => props.fontFamily};
  letter-spacing: 1px;
  font-weight: 300;
}
a{
  font-family: ${(props) => props.fontFamily};
  letter-spacing: 1px;
}
body{
    font-family: ${(props) => props.fontFamily};
    font-weight: ${(props) => props.fontWeight};
    background-color: ${(props) => props.bodyColor};
    letter-spacing: 1px;
}
input{
  background-color: #fff;
  padding: 15px!important;
  margin: 5px;
  border: 1px solid #d1d1d1;
  color: #a5a5a5!important;
  box-sizing: border-box;
}
input::placeholder{
  color: #a5a5a5!important;
}
input:focus, input:active{
  box-shadow: 0 0 0 0;
  border: 1px solid #c5c5c5!important;
    outline: 0;
}
.selectForm{
  width: 100%;
  padding: 15px!important;
  border: 1px solid #d1d1d1;
  color: #a5a5a5!important;
  background-color: #ebeaea;

}

.custom-select-form {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Polygon 2 */

position: absolute;
width: 26px;
height: 19px;
left: 99px;
top: 3796px;

background: #D1D1D1;
transform: rotate(-180deg);

.custom-select-form::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #ebeaea; /* Fundo branco para o ícone */
  background-image: url('data:image/svg+xml;utf8,<svg width="4" height="2" viewBox="0 0 4 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L0.267949 0.5L3.73205 0.5L2 2Z" fill="%23D1D1D1"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none; /* Para garantir que o ícone não interfira com a interação do select */
}

option{
  background-color: rgb(247 242 242 / 0%);
  padding: 7px;
  margin: 5px;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
  box-sizing: border-box;
}
option::placeholder{
  color: #6e6e6e;
}
option:focus, option:active{
  box-shadow: 0 0 0 0;
  border: 1px solid #6e6e6e;
    outline: 0;
}
.topoColor{
  background-color: ${(props) => props.bodyColor}!important;
}
.accordionColor{
  background-color: ${(props) => props.accordionColor}!important;
}
.accordionColorBranco{
  background-color: #fff!important;
}
.CorMenu{
  background-color: ${(props) => props.CorMenu}!important;
}
.Descricao{
  font-family: ${(props) => props.fontFamily}!important;
  color: ${(props) => props.DescricaoColor}!important;
  letter-spacing: 1px;
}

.Titulo{
  font-family: ${(props) => props.fontFamily}!important;
  color: ${(props) => props.TituloColor}!important;
  letter-spacing: 1px;
}
.CategoriasHomeColor{
  background-color: ${(props) => props.CategoriasHomeColor}!important;
}
.Menu-lateral .MuiPaper-root{
  background-color: #3ACBC7!important;
  color: #fff!important;;
  font-size: 1.5rem!important;
}

.Menu-lateral span{
  font-size: 1.5rem!important;
}

.Menu-lateral a{
  font-size: 1.5rem!important;
  color: #fff!important;;
  text-decoration: none!important;;
}
.SeparadorColor{
  background-color: ${(props) => props.SeparadorColor}!important;
  padding: 0px;
}
.caracteristicas_icone_grande::after{
    transform: rotate(45deg);
    background-color: #9ce5e3;
    content: " ";
    display: flex;
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    top: 4px;
    left: -24px;
    margin-right: 44px;
}
.recaptcha-container {
  height: "auto"; /* Altura original do ReCAPTCHA */
  text-align: center;
  justify-content: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.recaptcha-container > div {
  max-width: 100%;
  transform: scale(1.0); /* Aplique um inverso para compensar o redimensionamento */
  text-align: center;
  margin: 0 auto;
}
.caracteristicas_icone_pequeno::after{
    transform: rotate(45deg);
    background-color: #9ce5e3;
    content: " ";
    display: flex;
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    top: 5px;
    left: -12px;
    margin-right: 44px;
}

.MuiTabs-root{
  display: flex;
  align-items: center;
}

.MuiTabs-scroller{
  padding: 0px 0px!important;
  margin: 0px 13px!important;
}

.MuiTabs-scroller .MuiButtonBase-root{
  padding: 10px!important;
  min-height: 20px!important;
}

.MuiTabs-scroller .MuiTouchRipple-root{
  padding: 0px!important;
}

.MuiTabScrollButton-horizontal{
  background-color: ${LAYOUT.colors.dourado}!important;
  height: 19px!important;
  width: 19px!important;
  border-radius: 50px!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  color: #fff!important;
}

/* BLOCO CARD CARACTERISTICAS DAS SUITES */
.caracteristicasSuite{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  font-size: 14px;
  .iconCaracteristicas{
      font-size: ${LAYOUT.suites.caracteristicas.icon.size.default};
      @media only screen and (min-width: 0px) {
        font-size: ${LAYOUT.suites.caracteristicas.icon.size.xs};
      }
      @media only screen and (min-width: 600px) {
        font-size: ${LAYOUT.suites.caracteristicas.icon.size.sm};
      }
      @media only screen and (min-width: 900px) {
        font-size: ${LAYOUT.suites.caracteristicas.icon.size.md};
      }
      @media only screen and (min-width: 1200px) {
        font-size: ${LAYOUT.suites.caracteristicas.icon.size.lg};
      }
      @media only screen and (min-width: 1536px) {
        font-size: ${LAYOUT.suites.caracteristicas.icon.size.xl};
      }
    }
  .caracteristicasSuite-item{
    justify-content: start!important;
    align-items: center!important;
    flex-flow: nowrap;
  }
  .caracteristicasSuite-item p{
    font-family: ${LAYOUT.fonts.descricao};
    font-size: ${LAYOUT.suites.caracteristicas.fontSize.default};
    line-height: ${LAYOUT.suites.caracteristicas.lineHeight.default};
    @media only screen and (min-width: 0px) {
      font-family: ${LAYOUT.fonts.descricao};
      font-size: ${LAYOUT.suites.caracteristicas.fontSize.xs};
      line-height: ${LAYOUT.suites.caracteristicas.lineHeight.xs};
    }
    @media only screen and (min-width: 600px) {
      font-family: ${LAYOUT.fonts.descricao};
      font-size: ${LAYOUT.suites.caracteristicas.fontSize.sm};
      line-height: ${LAYOUT.suites.caracteristicas.lineHeight.sm};
    }
    @media only screen and (min-width: 900px) {
      font-family: ${LAYOUT.fonts.descricao};
      font-size: ${LAYOUT.suites.caracteristicas.fontSize.md};
      line-height: ${LAYOUT.suites.caracteristicas.lineHeight.md};
    }
    @media only screen and (min-width: 1200px) {
      font-family: ${LAYOUT.fonts.descricao};
      font-size: ${LAYOUT.suites.caracteristicas.fontSize.lg};
      line-height: ${LAYOUT.suites.caracteristicas.lineHeight.lg};
    }
    @media only screen and (min-width: 1536px) {
      font-family: ${LAYOUT.fonts.descricao};
      font-size: ${LAYOUT.suites.caracteristicas.fontSize.xl};
      line-height: ${LAYOUT.suites.caracteristicas.lineHeight.xl};
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    .caracteristicasSuite-item{
      align-items: start;

    }
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
  @media only screen and (max-width: 800px) {
    width: 90%;
  }
  @media only screen and (max-width: 713px) {
    width: 100%;
  }
  @media only screen and (max-width: 647px) {
    margin-left: 0px;
    width: 100%;
    p{
      font-size: 12px;
    }
    
  }
  @media only screen and (max-width: 430px) {
    width: 100%;
    .iconCaracteristicas{
      font-size: 12px;
    }
    p{
      font-size: 12px;
      letter-spacing: 0px;
    }
    
  }

  @media only screen and (max-width: 393px) {
    width: 100%;
    .iconCaracteristicas{
      font-size: 12px;
    }
    p{
      font-size: 12px;
      letter-spacing: 0px;
    }
    
  }
}
.modalCaracteristicas {
  position: absolute;
  top: calc(100vh - 500px - 20%);
  left: 25%;
  width: 50%;
  background-color: ${LAYOUT.colors.tiffanyClaro};
  padding: 20px;
  height: 500px;
  @media only screen and (min-width: 0px) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: 600px) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: 900px) {
    top: calc((100% - 500px) / 2);
    left: 0%;
    width: 100%;
    height: 500px;
    
  }
  @media only screen and (min-width: 1200px) {
    top: calc((100% - 500px) / 2);
    left: calc((100% - 800px) / 2);
    width: 800px;
    height: 500px;
  }
  @media only screen and (min-width: 1536px) {
    top: calc((100% - 500px) / 2);
    left: calc((100% - 800px) / 2);
    width: 800px;
    height: 521px;
  }
}

.content-modalCaracteristicas{
  overflow-y: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: -webkit-fill-available;

}

/* Estiliza a barra de rolagem */
.content-modalCaracteristicas::-webkit-scrollbar {
  right: 20px;
  width: 15px; /* Largura da barra de rolagem vertical */
  height: 15px; /* Altura da barra de rolagem horizontal */
}

/* Estiliza a parte da barra de rolagem (fundo) */
.content-modalCaracteristicas::-webkit-scrollbar-track {
  background: ${LAYOUT.colors.fundoInput};
}

/* Estiliza o polegar da barra de rolagem */
.content-modalCaracteristicas::-webkit-scrollbar-thumb {
  background-color: ${LAYOUT.colors.dourado}; /* Cor do polegar */
  border-radius: 10px; /* Arredondar o polegar */
  border: 3px solid #f1f1f1; /* Borda ao redor do polegar */
}

/* Estiliza o polegar ao passar o mouse */
.content-modalCaracteristicas::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Cor do polegar ao passar o mouse */
}



.MuiAccordion-region{
  margin-top: 0px!important;
}
.imgTour360{
  max-width: 100%;
}
/* Criando o select de parcelas */
.SelectParcelas{
  border: 1px solid #fff!important;
}
.SelectParcelas fieldset{
  border: none!important;
}
.SelectParcelas svg{
  color: #fff;
}
.SelectParcelas:hover{
  border: 1px solid #fff;
}
.SelectParcelas:focus{
  border: 1px solid #fff;
}
.SelectMenu{
  border: none!important;
}
.SelectParcelas:active{
  border: 1px solid #fff;
}
.inputParcelas{
  border: none!important;
}
.inputParcelas:hover{
  border: none!important;
}
.flip-card {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 400px;
  perspective: 1000px;
}
.flip-card-btn{
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999999;
  color: #000;
}

.flip-card-inner {
  position: relative;
  width: 100%!important;
  height: 400px!important;
  text-align: center;
  transition: transform 1.0s;
  transform-style: preserve-3d;
}

.flip-card-hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%!important;
  height: 400px!important;
  overflow: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #F3F3F3;
  color: white;
  transform: rotateY(180deg);
}
.config{
  position: fixed;
    background: #fff;
    float: right;
    right: 10px;
    bottom: 10px;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    z-index: 2;
}
/* .MuiAccordionSummary-content{
  margin-bottom: 0px!important;
} */
.btnBranco{
  color: #fff;
  border: 0px!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  border: 1px solid #fff!important;
  background-color: #9ce5e3;
  color: ${(props) => props.btnTextColor};
  :hover{
    cursor: pointer;
  }
}
.btnBrancoEscuro{
  color: #fff;
  border: 0px!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  border: 1px solid #fff!important;
  background-color: #3ACBC7;
  color: ${(props) => props.btnTextColor};
  :hover{
    cursor: pointer;
  }
}
.btnRoxo{
  color: #fff;
  border: 0px!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  border: none!important;
  background-color: #771d50;
  color: ${(props) => props.btnTextColor};
  :hover{
    cursor: pointer;
  }
}
.btnLaranja{
  color: #fff;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border: 0px!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  background-color: ${(props) => props.btnPrimary};
  color: ${(props) => props.btnTextColor};
  :hover{
    cursor: pointer;
  }
}

.btnCinza{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border: 0px!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  background-color: "#d3d3d3";
  color: ${LAYOUT.colors.dourado}!important;
  :hover{
    cursor: pointer;
  }
}
.btnTiffanyClaro{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  border: 1px solid #fff!important;
  box-shadow: none;
  width: 100px;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  padding: 8px;
  background-color: #abedeb;
  color: ${LAYOUT.colors.dourado}!important;
  :hover{
    cursor: pointer;
  }
}
.btnSaibaMais{
  color: ${LAYOUT.colors.cinza};
  border-bottom: 1px solid ${LAYOUT.colors.cinza};
  padding: 0px 2px;
  display: flex;
  text-decoration: dashed;
  font-family: "${(props) => props.fontFamily}", sans-serif!important;
  font-size: 14px;
}
.btnDourado{
  color: #fff;
  border: 0px!important;
  border-radius: 0px!important;
  text-decoration: none;
  font-size: 1.4rem;
  font-family: ${(props) => props.fontFamily};
  font-weight: 400;
  background-color: ${LAYOUT.colors.dourado};
  :hover{
    cursor: pointer;
  }
}

.textoValor{
  font-size: 22px;
  font-weight: bold!important;
  color: #000;
  margin: 0px;
  padding: 0px;
  font-family: ${LAYOUT.fonts.descricao}!important;
  @media only screen and (min-width: 0px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 1536px) {
    font-size: 26px;
  }
}
.textoValor.sifrao{
  font-size: 12px;
  font-weight: normal!important;
  margin-top: 4px;
  color: #5c5c5c;
  font-family: ${LAYOUT.fonts.descricao}!important;
  @media only screen and (min-width: 0px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 3px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1536px) {
    margin-top: 3px;
    font-size: 20px;
  }
}

/* sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536, */
.ativo{
  display: none!important;
  transition: width 60s;
}
.ativoMenu{
  display: contents!important;
}
.videoHome{
    display: block;
    @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.videoHomeMobile{
    display: none;
    @media only screen and (max-width: 1100px) {
    display: block;
  }
}
.calendario{
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconeCalendario{
  width: 50px;
}

.pacotes-carousel .alice-carousel__stage-item{
  padding: 10px;
}

.pacotes-carousel .alice-carousel__next-btn .alice-carousel__next-btn-wrapper{
  text-align: right;
    font-size: 20px;
}
.pacotes-carousel .alice-carousel__next-btn{
  position: absolute;
  right: 0;
  top: 103px;
}
.pacotes-carousel .alice-carousel__prev-btn{
  position: absolute;
    left: 0;
    top: 103px;
}
.pacotes-carousel .alice-carousel__prev-btn .alice-carousel__prev-btn-wrapper{
  text-align: left;
  font-size: 20px;
}
.ativoScroll{
  position: fixed!important;
  top: 0px!important;
  height: 100px!important;
  background-color: #D8F4F3!important;
  .dropdown-content {
        background-color: #D8F4F3!important;
  }
  
  .logoMenuScroll{
    display: flex;
    opacity: 1.0;
    max-width: 70px;
  }
  .reservaMenuScroll{
    @media only screen and (max-width: 1100px) {
      display: flex;
    opacity: 1.0;
    max-width: 70px;
  }
  }
  
}
.logoScroll{
  position: fixed!important;
  top: -88px!important;
  opacity: 0.0;
}
.reservaMenuScroll{
    display: flex;
  overflow: hidden;
  max-width: 0;
  opacity: 0.0;
  transition: all ease 1.0s;
    
  }

  .form-comprador{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  .form-comprador.active{
    max-height: 1500px;
  }
.logoMenuScroll{
  display: flex;
  overflow: hidden;
  max-width: 0;
  opacity: 0.0;
  transition: all ease 1.0s;
}
.mesesPacotes .alice-carousel__stage-item{
  width: 100px!important;
}
.mesesPacotes .avatarPacotes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MenuScroll{
    display: none;
    background-color: ${(props) => props.bodyColor};
    z-index: 1;
    position: fixed;
    height: 70px;
    transition: all ease 1.0s;
    &.transitionMenu {
    display: flex;
    animation: scale-display--menu 0.3s;
    border-bottom: 1px solid #fff;
  }
  &.outMenu {
    display: none;
    animation:scale-display--menu--reversed 0.5s;
  }
  @keyframes scale-display--menu {
      0% {
        opacity: 0;
        height: 0px;
        /* transform: scale(0);
    -webkit-transform: scale(0); */
      }

      100% {
        opacity: 1;
        height: 70px;
        /* transform: scale(1);
    -webkit-transform: scale(1); */
      }
    }
    @keyframes scale-display--menu--reversed {
      0% {
        opacity: 1;
        height: 70px;
      }
      100% {
        opacity: 0;
        height: 0px;
      }
    }
  }
  .rdrMonthName::first-letter {
  text-transform: uppercase!important;
  }
  .rdrMonthAndYearPickers {
  display: none!important;
  }
  .rdrDay {
    background: transparent;
    user-select: none;
    border: 1px solid #afacac!important;
    padding: 0;
    line-height: 3.000em;
    height: 3em;
    text-align: center;
    color: #1d2429;
    margin: 1px;
    box-sizing: inherit;
    flex-basis: calc(94% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
}
.rdrStartEdge {
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    left: 0px!important;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 0px!important;
    left: 0;
    right: 0;
    bottom: 0px!important;
    height: 100%!important;
}
.rdrEndEdge {
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    right: 0px!important;
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 0px!important;
    left: 0;
    right: 0;
    bottom: 0px!important;
    height: 100%!important;
}
.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    left: 0px!important;
}
.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    right: 0px!important;
}
.rdrMonthName {
    text-align: center;
    font-weight: 600;
    color: #849095;
    padding: 0.833em;
}
.calendarioDrawer {
    max-width: 550px!important;
    position: relative;
    border: 1px solid #bfbebe;
    margin-top: 13px;
    margin-bottom: 10px;
}
.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    position: absolute;
    width: 100%;
    top: -19px;
}
.MuiAccordion-region{
  min-width: 370px!important;
  margin-top: 20px;
}

/* PAGINA SUITES */
.infosInline{
  display: flex;
    /* width: 100%!important; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.infosInline li{
  margin-left: 20px;
}
.infosInline li::before{
    unicode-bidi: isolate !important;
    font-variant-numeric: tabular-nums !important;
    text-transform: none !important;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
/* PAGINA DETALHES-SUITE */
.infosSuite{
}
.infosSuite li{
  margin-left: 20px;
  line-height: 1.5;
}
.infosSuite li::before{
    unicode-bidi: isolate !important;
    font-variant-numeric: tabular-nums !important;
    text-transform: none !important;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 175, 255, 1) 0%,
    rgba(63, 97, 255, 1) 100%
  );
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
    128deg,
    rgba(255, 154, 63, 1) 0%,
    rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
    128deg,
    rgba(182, 255, 64, 1) 0%,
    rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
    128deg,
    rgba(189, 255, 83, 1) 0%,
    rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
    128deg,
    rgba(64, 255, 242, 1) 0%,
    rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
    128deg,
    rgba(255, 64, 156, 1) 0%,
    rgba(255, 63, 63, 1) 100%
  );
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
}

.keen-slide-size{
  position: relative;
  width: 100%!important;
  @media only screen and (max-width: 1200px) {
    width: 100%!important;
    color: #000;
  }
  @media only screen and (max-width: 960px) {
    width: 100%!important;
    color: #000;
  }
  @media only screen and (max-width: 700px) {
    width: 100%!important;
    color: #000;
  }
  @media only screen and (max-width: 606px) {
    width: 100%!important;
    color: #000;
  }
}
.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #f0851b;
  cursor: pointer;
}



.arrow--left {
  left: 0px;
}

.arrow--right {
  right: 0px;
}

.arrow--disabled {
  fill: rgb(240 133 27 / 43%);
}

.Slider-suites{
  display: flex;
  flex-direction: row;
  width: 800px;
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: auto;
  max-height: 100vh;
}

.number-slide1 {
  background: rgb(64, 175, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 175, 255, 1) 0%,
    rgba(63, 97, 255, 1) 100%
  );
}

.number-slide2 {
  background: rgb(255, 75, 64);
  background: linear-gradient(
    128deg,
    rgba(255, 154, 63, 1) 0%,
    rgba(255, 75, 64, 1) 100%
  );
}

.number-slide3 {
  background: rgb(182, 255, 64);
  background: linear-gradient(
    128deg,
    rgba(182, 255, 64, 1) 0%,
    rgba(63, 255, 71, 1) 100%
  );
  background: linear-gradient(
    128deg,
    rgba(189, 255, 83, 1) 0%,
    rgba(43, 250, 82, 1) 100%
  );
}

.number-slide4 {
  background: rgb(64, 255, 242);
  background: linear-gradient(
    128deg,
    rgba(64, 255, 242, 1) 0%,
    rgba(63, 188, 255, 1) 100%
  );
}

.number-slide5 {
  background: rgb(255, 64, 156);
  background: linear-gradient(
    128deg,
    rgba(255, 64, 156, 1) 0%,
    rgba(255, 63, 63, 1) 100%
  );
}

.number-slide6 {
  background: rgb(64, 76, 255);
  background: linear-gradient(
    128deg,
    rgba(64, 76, 255, 1) 0%,
    rgba(174, 63, 255, 1) 100%
  );
}

.tabDPNY .MuiTabs-scroller .MuiTabs-indicator{
  position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #d09c1a;
}
.tabDPNY .tabDPNY{
  font-size: ${LAYOUT.fonts.fontSize.default};
  color:#ce9c1a !important;
  font-family: ${LAYOUT.fonts.family.descricao}!important;
  font-weight: 400!important;
  @media only screen and (min-width: 0px) {
    font-size: ${LAYOUT.fonts.fontSize.descricao.xs};
  }
  @media only screen and (min-width: 600px) {
    font-size: ${LAYOUT.fonts.fontSize.descricao.sm};
  }
  @media only screen and (min-width: 900px) {
    font-size: ${LAYOUT.fonts.fontSize.descricao.md};
  }
  @media only screen and (min-width: 1200px) {
    font-size: ${LAYOUT.fonts.fontSize.descricao.lg};
  }
  @media only screen and (min-width: 1536px) {
    font-size: ${LAYOUT.fonts.fontSize.descricao.xl};
  }
}

.tabDPNY button[aria-selected="true"]{
  font-weight: 400;
}

.react-calendar{
  border-radius: 10px;
  border: 2px solid ${LAYOUT.colors.dourado};
}

.react-calendar span{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 600;
}



/* CustomDatePicker.css */
.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 18px;
  background-color: #f0f0f0;
}

.custom-prev-button,
.custom-next-button {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  padding: 0 10px;
}

.custom-prev-button:disabled,
.custom-next-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.react-datepicker {
  font-family: Arial, sans-serif;
}

.react-datepicker__day--disabled {
  color: #ccc;
  cursor: not-allowed;
  background-color: #f9f9f9;
}

/* Aumentar o tamanho do calendário */
.react-datepicker {
  font-size: 12px; /* Aumentar o tamanho da fonte */
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 2rem; /* Aumentar a largura dos dias */
  height: 2rem; /* Aumentar a altura dos dias */
  line-height: 2rem; /* Alinhar verticalmente */
}

.react-datepicker__current-month {
  font-size: 1.5rem; /* Aumentar o tamanho da fonte do mês atual */
}

.react-datepicker__header {
  padding-top: 1rem; /* Ajustar o padding do cabeçalho */
  padding-bottom: 1rem; /* Ajustar o padding do cabeçalho */
}

.react-datepicker__month-container {
  width: auto; /* Ajustar a largura do contêiner do mês */
}

.react-datepicker__month {
  margin: 1rem; /* Ajustar a margem do mês */
}

.react-datepicker__navigation {
  top: 1rem; /* Ajustar a posição dos botões de navegação */
}

/* CSS DO RODAPÉ DO SITE */

.premios{
  display: flex;
  position: relative;
  flex-direction: row!important;
  margin-top: 30px!important;
  justify-content: start!important;
  margin-left: 0px!important;
  @media only screen and (min-width: 600px) {
    margin-bottom: 15px;
  }
}
.premios-titulo{
  font-size: 12px;
  position: absolute;
  top: -20px;
  font-weight: 400;
  left: 3px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (min-width: 600px) {
    top: -25px;
    font-size: 14px;
  }
}
.iconPremios{
  width: 100px;
  height: auto;
  @media only screen and (min-width: 0px) {
    width: 70px;
    height: auto;
  }
  @media only screen and (min-width: 600px) {
    width: 80px;
    height: auto;
  }
  @media only screen and (min-width: 900px) {
    width: 80px;
    height: auto;
  }
  @media only screen and (min-width: 1200px) {
    width: 80px;
    height: auto;
  }
  @media only screen and (min-width: 1533px) {
    width: 100px;
    height: auto;
  }
}
.newslatterDesktop{
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.newslatterMobile{
  display: block;
  @media only screen and (min-width: 600px) {
    display: none!important;
  }
}

.sociais{
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 30px!important;
  justify-content: start;
  align-items: center;
  @media only screen and (max-width: 700px) {
    justify-content: start;
  }
}
.sociais-titulo{
  font-size: 12px;
  position: absolute;
  top: -20px;
  font-weight: 400;
  left: 3px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (min-width: 600px) {
    top: -25px;
    font-size: 14px;
  }
}

.containerSuite {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.containerSuite.show {
  max-height: 1000px; /* ajuste o valor para caber o conteúdo */
  opacity: 1;
}

.dots {
  display: flex;
  padding: 0px 0;
  justify-content: center;
  margin-top: 10px!important;
}

.dot {
  border: 1px solid ${LAYOUT.colors.dourado};
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: ${LAYOUT.colors.dourado}!important;
}

.swiper-pagination-bullet{
  border: 1px solid ${LAYOUT.colors.dourado};
  width: 8px!important;
  height: 8px!important;
  background: #fff;
  border-radius: 50%;
  margin: 0 5px;
  padding: 4px!important;
  opacity: 1!important;
  cursor: pointer;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background: ${LAYOUT.colors.dourado}!important;
  opacity: 1!important;
}
.swiper-pagination{
  bottom: 0px !important;
}


/* CSS CALENDARIO */

.custom-calendar-container{
  width: 100%;
}

.rmdp-wrapper{
  width: 95%!important;
  border-radius: 0px!important;
  padding: 10px;
  @media only screen and (min-width: 0px) {
    width: 70%!important;

  }
  @media only screen and (min-width: 430px) {
    width: 95%!important;

  }
  @media only screen and (min-width: 550px) {
    width: 80%!important;

  }
  @media only screen and (min-width: 700px) {
    width: 100%!important;

  }

}

.rodape{
  margin-bottom: 80px!important;
  @media only screen and (max-width: 740px) {
    margin-bottom: 60px!important;

  }
}

.rmdp-calendar{
  width: 100%!important;
}

.rmdp-day-picker > div{
  width: 100%;
}

.rmdp-week-day{
  width: 100%!important;
  height: auto!important;
}

.rmdp-day{
  width: 100%!important;
  height: 30px!important;
}

.rmdp-week-day{
  color: #868686!important;
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 500!important;
}
.rmdp-day .sd{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 500!important;
  color: #868686;
}
.rmdp-day.rmdp-disabled span{
  font-family: 'Montserrat', sans-serif!important;
  font-weight: 500!important;
  color: #c4c4c4;
}
.rmdp-day.rmdp-disabled .diaCalendarioDourado{
  color: ${LAYOUT.colors.tiffany}!important;
  opacity: 0.5!important;
}

.rmdp-day.rmdp-range .sd{
  color: #fff;
}
.rmdp-day.rmdp-today span{
  background-color: #fff!important;
  color: #000!important;
  font-weight: 600!important;
  border-radius: 0%!important;
}
.rmdp-range{
  background-color: ${LAYOUT.colors.tiffany}!important;
  border-radius: 0px;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.rmdp-range.end{
  border-bottom-right-radius: 0%!important;
  border-top-right-radius: 0%!important;
}

.rmdp-range.start{
  border-bottom-left-radius: 0%!important;
    border-top-left-radius: 0%!important;
}

.rmdp-day.rmdp-selected .sd{
  background-color: ${LAYOUT.colors.tiffany}!important;
  border-radius: 0px;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.rmdp-day .sd:hover{
  background-color: ${LAYOUT.colors.tiffany}!important;
  box-shadow: 0 0 3px #8798ad;
  border-radius: 0%!important;
  color: #fff;
}

.rmdp-range .diaCalendarioDourado{
  color: #fff!important;
}

.rmdp-header-values span{
    color: #000;
    font-family: 'Montserrat', sans-serif!important;
    margin: auto;
    font-weight: 600!important;
}
.diaCalendarioDourado{
  color: ${LAYOUT.colors.dourado}!important;
}

.flipbookContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 1000px; /* Largura máxima para desktop */
  margin: auto; /* Centraliza o conteúdo */
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem; /* Ajuste o tamanho da fonte conforme necessário */
  background: #f5f5f5; /* Cor de fundo para as páginas */
  border: 1px solid #ddd; /* Borda para as páginas */
}

.cartas-abertas{
  background-color: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.cartas-abertas-conteudo{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cartas-abertas-conteudo .content{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.cartas-abertas-conteudo .logo-dpny{
  width: 156px;
}

.cartas-abertas-conteudo .content .esg{
  width: 100%;
  max-width: 570px;
}
.cartas-abertas-conteudo .content .rodape{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cartas-abertas-conteudo .content .sociais{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
.cartas-abertas-conteudo .content .endereco{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cartas-abertas-conteudo .content .sociais img{
  width: 30px;
  margin: 0px 15px;
}
.cartas-abertas-conteudo .content .corpo-carta div{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 992px;
}

.cartas-abertas-conteudo .conteudo-html{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
}

.cartas-abertas-conteudo .conteudo-html p{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif!important;
  text-align: left;
  line-height: 1.8;
}

.cartas-abertas-conteudo .conteudo-html h3{
  text-align: left;
}
.cartas-abertas .content{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.cartas-abertas .content div{
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 550px;
}

.cartas-abertas .content div .alcance{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cartas-abertas .content div h1{
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
  margin-top: 20px;
}

.cartas-abertas .content div.alcance span{
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
}


.cartas-abertas .logo-dpny{
  width: 156px;
}

.cartas-abertas .content p{
  color: #fff;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif!important;
  text-align: center;
  padding: 0px;
}

.cartas-abertas .nao-importa{
  width: 350px;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .flipbookContainer {
    width: 100%; /* Largura total em dispositivos móveis */
    height: auto; /* Altura automática para manter a proporção */
  }

  .page {
    font-size: 1.5rem; /* Reduz o tamanho da fonte em dispositivos móveis */
  }
}

.MuiBackdrop-root.MuiModal-backdrop{
  opacity: 0!important;
}



`;

const GlobalStylesWrapper = (props: DefaultTheme) => (
  <>
    <GlobalStyles
      padding={props.padding}
      fontWeight={props.fontWeight}
      importFont={props.importFont}
      fontFamily={props.fontFamily}
      btnSite="#000"
      colors={{ orange: "#ff8f00" }}
      btnPrimary={props.btnPrimary}
      DescricaoColor={props.DescricaoColor}
      TituloColor={props.TituloColor}
      bodyColor={props.bodyColor}
      CategoriasHomeColor={props.CategoriasHomeColor}
      SeparadorColor={props.SeparadorColor}
      CorMenu={props.CorMenu}
      btnTextColor={props.btnTextColor}
      accordionColor={props.accordionColor}
      config={props.config}
    />
  </>
);

export default GlobalStylesWrapper;
