import { ParsedUrlQuery } from "querystring";

export function utmCookie(d: any, query: ParsedUrlQuery) {
  if (getCookie("utm_campaign")) {
  } else {
    d.cookie = `utm_campaign=${query.utm_campaign}; path=/; max-age=2592000`; // 30 dias
    d.cookie = `utm_content=${query.utm_content}; path=/; max-age=2592000`;
    d.cookie = `utm_id=${query.utm_id}; path=/; max-age=2592000`;
    d.cookie = `utm_medium=${query.utm_medium}; path=/; max-age=2592000`;
    d.cookie = `utm_source=${query.utm_source}; path=/; max-age=2592000`;
    d.cookie = `utm_term=${query.utm_term}; path=/; max-age=2592000`;
  }

  if (getCookie("utm_history")) {
    const history = getCookie("utm_history") as string;
    const convHistory = Buffer.from(history, "base64").toString("utf-8");
    const jsonHistory = JSON.parse(convHistory);
    jsonHistory.push({
      utm_campaign: query.utm_campaign,
      utm_content: query.utm_content,
      utm_id: query.utm_id,
      utm_medium: query.utm_medium,
      utm_source: query.utm_source,
      utm_term: query.utm_term,
      data: new Date(),
    });
    const decodedHistory = Buffer.from(JSON.stringify(jsonHistory)).toString(
        "base64"
      );
    d.cookie = `utm_history=${decodedHistory}; path=/; max-age=2592000`;
  } else {
    const history = [
      {
        utm_campaign: query.utm_campaign,
        utm_content: query.utm_content,
        utm_id: query.utm_id,
        utm_medium: query.utm_medium,
        utm_source: query.utm_source,
        utm_term: query.utm_term,
        data: new Date(),
      },
    ];
    const decodedHistory = Buffer.from(JSON.stringify(history)).toString(
      "base64"
    );
    d.cookie = `utm_history=${decodedHistory}; path=/; max-age=2592000`;
  }
  //   const decodedId = Buffer.from(id, "base64").toString("utf-8");
}

const getCookie = (name: any) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
};
